import LoginView from '@eq/components/LoginView'
import { useUser } from '@eq/hooks/useUser'
import { GetServerSidePropsContext } from 'next'
import { getCsrfToken } from 'next-auth/client'
import { useRouter } from 'next/router'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const next = context?.query?.next || null
  const email = context?.query?.email || null
  const csrfToken = await getCsrfToken(context)

  return {
    props: {
      next,
      email,
      csrfToken,
    },
  }
}

export default function LoginPage(props) {
  const router = useRouter()
  useUser(router.isReady ? { redirectIfFound: true, redirectTo: props.next } : undefined)
  return (
    <div className="min-h-screen flex place-items-center justify-center max-w-screen">
      <LoginView {...props} />
    </div>
  )
}
